import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Response = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 601px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  return (
    <div>
      {isMobile && <p>Stai usando un cellulare</p>}
      {isTablet && <p>Stai usando un tablet</p>}
      {isDesktop && <p>Stai usando un PC</p>}
    </div>
  );
};

export default Response;

