import React from 'react';

function Header() {
  return (
    <header style={{ backgroundColor: 'yellow', color: 'blue' }}>
      <h1>Benvenuto sul sito di Gabriele Serpi</h1>
    </header>
  );
}

export default Header;

