import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Post from './Post';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import './SwiperStyles.css';
import logo from '../logo.png'; // Assicurati che il percorso sia corretto

function InstagramFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentYear = new Date().getFullYear(); // Ottieni l'anno corrente

  useEffect(() => {
    setLoading(true);
    fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,children{media_url,media_type}&access_token=IGQWROWm8xWmNZALV9BWW9OZAW5MYkJPYWhfSVRIZAHA4bjJvekdXeVFlR3J2d2JPTGw2aXR3ZAndTMTdwZA3ZAIZAUxfU2VUWkE0ZAFdYWFZANVl9oTTFuRjZAiSDB0UzhaYzhDR2gxUHVRdWZAINVVwcUFlR3pqMU5kb2NkZAWMZD`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Errore nella risposta del server');
      }
      return response.json();
    })
    .then(data => {
      if (data.data && data.data.length > 0) {
        setPosts(data.data);
      } else {
        setError('Nessun post trovato');
      }
    })
    .catch(error => {
      console.error('Errore nel recupero dei post:', error);
      setError('Si è verificato un errore nel caricamento dei post');
    })
    .finally(() => setLoading(false));
  }, []);

  const handleSlideChange = (swiper) => {
    // Mostra gli elementi
    const elements = document.querySelectorAll('.swiper-pagination-fraction, .swiper-button-next, .swiper-button-prev, .hand-icon');
    elements.forEach(el => el.classList.remove('hidden'));

    // Nascondi gli elementi dopo 3 secondi
    setTimeout(() => {
      elements.forEach(el => el.classList.add('hidden'));
    }, 3000);
  };

  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  const handleVideoPlay = (e) => {
    e.preventDefault();
    const video = e.target;
    setTimeout(() => {
      video.play();
    }, 10000); // Ritardo di 10 secondi prima di iniziare la riproduzione
  };

  const handleVideoEnd = (e) => {
    e.preventDefault();
    const video = e.target;
    setTimeout(() => {
      video.pause();
    }, 10000); // Ritardo di 10 secondi dopo la fine della riproduzione
  };

  if (loading) {
    return <div>Caricamento...</div>;
  }

  if (error) {
    return <div>Errore: {error}</div>;
  }

  if (posts.length === 0) {
    return <div>Nessun post disponibile</div>;
  }

  return (
    <div>
      {posts.map(post => (
        <div key={post.id} className="swiper-container">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{
              type: 'fraction',
              el: '.swiper-pagination-fraction',
            }}
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
          >
            {post.media_type === 'CAROUSEL_ALBUM' && post.children
              ? post.children.data.map(child => (
                  <SwiperSlide key={child.id}>
                    {child.media_type === 'VIDEO' ? (
                      <video controls onContextMenu={disableContextMenu} onPlay={handleVideoPlay} onEnded={handleVideoEnd}>
                        <source src={child.media_url} type="video/mp4" />
                      </video>
                    ) : (
                      <>
                        <img src={child.media_url} alt={post.caption || ''} onContextMenu={disableContextMenu} />
                        <div className="overlay"></div>
                      </>
                    )}
                    <div className="watermark">© Gabriel Serpi - {currentYear} - www.gabrieleserpi.com</div>
                    <img src={logo} alt="Logo" className="logo" />
                  </SwiperSlide>
                ))
              : (
                <SwiperSlide>
                  {post.media_type === 'VIDEO' ? (
                    <video controls onContextMenu={disableContextMenu} onPlay={handleVideoPlay} onEnded={handleVideoEnd}>
                      <source src={post.media_url} type="video/mp4" />
                    </video>
                  ) : (
                    <>
                      <img src={post.media_url} alt={post.caption || ''} onContextMenu={disableContextMenu} />
                      <div className="overlay"></div>
                    </>
                  )}
                  <div className="watermark">© Gabriel Serpi - {currentYear} - www.gabrieleserpi.com</div>
                  <img src={logo} alt="Logo" className="logo" />
                </SwiperSlide>
              )}
            <div className="swiper-button-next hidden"></div>
            <div className="swiper-button-prev hidden"></div>
            <div className="swiper-pagination-fraction hidden"></div>
            <div className="hand-icon hand-icon-next hidden">👉</div>
            <div className="hand-icon hand-icon-prev hidden">👈</div>
          </Swiper>
          <Post content={post.caption || ''} />
        </div>
      ))}
    </div>
  );
}

export default InstagramFeed;
