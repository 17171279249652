import React, { useState } from 'react';

function Post({ content }) {
  const [expanded, setExpanded] = useState(false);
  const maxLength = 100;

  if (!content) return null; // Aggiungi questo controllo

  const truncatedContent = content.length > maxLength 
    ? content.slice(0, maxLength) + '...' 
    : content;

  return (
    <div className="post">
      <div className="post-content"  style={{ whiteSpace: 'pre-wrap' }}>
        {expanded ? content : truncatedContent}
      </div>
      {content.length > maxLength && !expanded && (
        <span className="read-more" onClick={() => setExpanded(true)}>
          Continua a leggere
        </span>
      )}
      {expanded && (
  <span 
    className="read-less" 
    onClick={() => setExpanded(false)}
    style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline' }}
  >
    Mostra meno
  </span>
)}
    </div>
  );
}

export default Post;