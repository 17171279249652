import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import InstagramFeed from './components/InstagramFeed';
import Response from './components/Response';
import LiveStream from './components/LiveStream';
import Navbar from './components/Navbar';
import Login from './components/Login';
import './App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function App() {
  const [fontSize, setFontSize] = useState(16);
  const [language, setLanguage] = useState(navigator.language || 'it');
  // eslint-disable-next-line no-unused-vars
  const [originalText, setOriginalText] = useState('Hello, how are you?');
  const [translatedText, setTranslatedText] = useState('Ciao, come stai?');

  const increaseFontSize = () => {
    setFontSize(fontSize + 2);
  };

  const decreaseFontSize = () => {
    setFontSize(fontSize - 2);
  };

  const translateText = async (text, lang) => {
    try {
      const response = await axios.post('https://translation.googleapis.com/language/translate/v2', {}, {
        params: {
          q: text,
          target: lang,
          key: 'YOUR_API_KEY' // Sostituisci con la tua chiave API di Google Translate
        }
      });
      setTranslatedText(response.data.data.translations[0].translatedText);
    } catch (error) {
      console.error('Errore nella traduzione:', error);
    }
  };

  return (
    <Router>
      <div className="App" style={{ fontSize: `${fontSize}px`, backgroundColor: '#000', color: '#FFD700' }}>
        <Header />
        <Navbar />
        <Response />
        <div className="text-controls">
          <button onClick={increaseFontSize} aria-label="Aumenta la dimensione del testo">A+</button>
          <button onClick={decreaseFontSize} aria-label="Diminuisci la dimensione del testo">A-</button>
          <select id="language" value={language} onChange={(e) => setLanguage(e.target.value)} aria-label="Seleziona la lingua">
            <option value="it">Italiano</option>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
          </select>
          <button onClick={() => translateText(originalText, language)} aria-label="Traduci il testo">Traduci</button>
        </div>
        <div className="translated-text">
          {translatedText}
        </div>
        <Routes>
          <Route path="/" element={<InstagramFeed />} />
          <Route path="/login" element={<Login />} />
          <Route path="/livestream" element={<LiveStream />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;