import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Livestream() {
  const [videoId, setVideoId] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchVideoId = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://173.249.56.90:5000/api/videos/current-video', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setVideoId(response.data.videoId);
      } catch (error) {
        setError(error.response.data.message || 'An error occurred');
      }
    };

    fetchVideoId();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!videoId) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Livestream</h2>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default Livestream;